<template>
  <div
    class="operation-log"
    :style="{ height: scrollHeight || ownScrollHeight }"
    :class="{ collapsed: collapsed }"
  >
    <a-icon
      class="trigger"
      :type="collapsed ? 'menu-unfold' : 'menu-fold'"
      @click="changeCollapsed"
    />
    <section class="content" v-show="!collapsed" style="margin-top: 0">
      <div class="formContentGoods" style="margin: 0; padding: 0 0 10px 0">
        {{ $t('操作日志') }}
      </div>
      <div style="width: 100%">
        <a-timeline class="formContentGoods">
          <a-timeline-item v-for="item in list" :key="item.purchaseOrderActionId">
            <div class="item-text" v-html="item.content" :style="{ color: item.color }"></div>
            <div v-if="item.created_at" style="color: #999999">
              {{ item.created_user }} {{ item.created_at }}
            </div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </section>
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
export default {
  props: {
    actionSn: {
      type: String,
    },
    type: {
      type: String,
    },
    defaultCollapsed: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {},
  },
  watch: {
    defaultCollapsed: {
      immediate: true,
      handler(val) {
        this.collapsed = val
      },
    },
  },
  computed: {
    ownScrollHeight() {
      let height = window.innerHeight - 280
      return height * this.$store.state.rate + 'px'
    },
  },
  data() {
    return {
      list: [],
      collapsed: false,
    }
  },
  methods: {
    changeCollapsed() {
      this.collapsed = !this.collapsed
    },
    queryList() {
      http({
        url: api.getCommonAction,
        type: 'post',
        data: {
          actionSn: this.actionSn,
          actionCategory: this.type,
        },
        success: (res) => {
          this.list = res.result
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.operation-log {
  background-color: transparent;
  font-size: 12px;
  width: 300px;
  overflow: auto;
  position: relative;
  min-height: 100px;
  &.collapsed {
    width: 50px;
  }

  .formContentGoods {
    margin-bottom: 16px;
    background-color: white;
    color: #333333;
    font-size: 14px;
  }
  .trigger {
    position: absolute;
    top: 4px;
    right: 20px;
    font-size: 18px;
  }
}
.item-text {
  word-wrap: break-word;
  word-break: break-all;
  display: inline-block;
}
</style>
