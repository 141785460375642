<template>
  <a-modal
    :title="$t('资金账户')"
    v-model="visible"
    width="1000px"
    :keyboard="false"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <CommonTable
      ref="ModalCommonTableRef"
      :columns.sync="columns"
      :showSelect="true"
      :selectedRowKeys.sync="selectedRowKeys"
      :selectedRows.sync="selectedRows"
      :dataSource="tableData"
      :loading="loading"
      :total="total"
      @select="onSelectChange"
      @search="getList"
    ></CommonTable>
  </a-modal>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import { convertKeysToCamelCase } from '@/common'
export default {
  props: {
    businessUnitCode: {},
    hasSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      tableData: [],
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],

      columns: [
        {
          title: this.$t('银行账号'),
          dataIndex: 'code',
          width: 200,
        },
        {
          title: this.$t('账户名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('金融机构类别'),
          dataIndex: 'type',
          customRender: (text) => this.$store.getters.capitalTypeMapping[text],
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('银行名称'),
          dataIndex: 'bankName',
        },
        {
          title: this.$t('账户用途'),
          dataIndex: 'accountPurpose',
          customRender: (text) => this.$store.getters.accountPurposeMapping[text],
        },
        {
          title: this.$t('存款类型'),
          dataIndex: 'subType',
          customRender: (text) => this.$store.getters.bankCapitalTypeMapping[text],
        },
      ],
    }
  },
  methods: {
    open() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = true
      this.$nextTick(() => {
        this.$refs.ModalCommonTableRef.queryList()
      })
    },
    close() {
      this.visible = false
    },
    async getList() {
      this.loading = true
      await http({
        url: api.getCapitalAccountList,
        data: {
          status: 1,
          businessUnitCode: this.businessUnitCode,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list.filter((item) => !this.hasSelected?.includes(item.id))
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    onSelectChange(ids, rows) {
      this.selectedRows = rows
    },
    handleOk() {
      if (this.selectedRows.length === 0) {
        this.$message.warning(this.$t('请选择银行账号'))
        return
      }
      this.$emit('ok', this.selectedRows)
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>
