<template>
  <div class="content align-center" style="min-height: 0">
    <a-button
      type="primary"
      v-if="isDetail && !isEdit && ['DRAFT', 'WAIT_CHECK'].includes(status) && canEdit"
      @click="handleEdit"
    >
      {{ $t(`编辑`) }}
    </a-button>
    <template v-if="(isDetail && isEdit) || !isDetail">
      <a-button
        type="primary"
        @click="onSubmit('DRAFT')"
        :loading="draftLoading"
        v-if="status === 'DRAFT'"
      >
        {{ $t(`暂存`) }}
      </a-button>
      <a-button type="primary" @click="onSubmit('WAIT_CHECK')" :loading="saveLoading">
        {{ $t(`提交`) }}
      </a-button>
    </template>
    <a-button type="default" v-if="isDetail && isEdit" @click="cancelEdit">
      {{ $t(`取消`) }}
    </a-button>
    <a-button type="default" @click="backForm">
      {{ $t(`返回`) }}
    </a-button>
  </div>
</template>
<script>
export default {
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    draftLoading: {
      type: Boolean,
      default: false,
    },
    saveLoading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleEdit() {
      this.$emit('update:isEdit', true)
      this.$emit('edit')
    },
    onSubmit(status) {
      this.$emit('submit', status)
    },
    cancelEdit() {
      this.$emit('update:isEdit', false)
      this.$emit('cancel')
    },
    backForm() {
      this.$emit('back', 1)
      // this.$router.back()
    },
  },
}
</script>
