<template>
  <a-select
    style="width: 100%"
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :filter-option="filterOption"
    @change="onChange"
  >
    <a-select-option v-for="(item, index) in option" :value="item.code" :key="index">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  mounted() {
    this.getList()
  },
  props: {
    placeholder: {},
    disabled: {},
    code: {},
    name: {},
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder || this.$t(`币种`)
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  methods: {
    getList() {
      if (!this.$store.state.currencyList?.length) return
      this.option = this.$store.state.currencyList
      if (this.code != undefined) {
        this.onChange(this.code)
      } else if (this.option.length === 1) {
        this.onChange(this.option[0].code)
      }
    },
    onChange(value) {
      this.selectedValue = value
      this.$emit('update:code', value)
      let item = this.option.find((elem) => elem.code === value)
      this.$emit('update:name', item?.name)
      this.$emit('change', item)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  watch: {
    code(val) {
      this.selectedValue = val
    },
    '$store.state.currencyList': {
      handler(val) {
        if (val?.length > 0) {
          this.getList()
        }
      },
      immmediate: true,
    },
  },
}
</script>
